<template>
  <component :is="componentToShow"
             :to="cta ? cta.href : ''"
             :target="cta && cta.href && cta.href.toLowerCase().startsWith('http') ? '_blank' : ''"
             class="relative z-10 flex flex-col justify-end w-full overflow-hidden text-white bg-cover shadow-2xl group md:aspect-w-19 md:aspect-h-10 min-h-[512px] md:min-h-0 max-w-[1920px] xl:mx-auto"
             @click="handleClick"
  >
    <!-- Wrapper for @tailwindcss/aspect-ratio padding trick -->
    <div class="flex flex-col justify-end w-full p-6 sm:p-8 md:p-12 lg:p-16 min-h-[512px] md:min-h-0 max-w-[1920px]">
      <!-- Clip path for Safari mouse over effect, to clip rounded corners -->
      <div v-if="backgroundImage"
           class="absolute inset-0 w-full h-full"
           :style="{ clipPath: 'content-box' }"
      >
        <NuxtImg :src="backgroundImage"
                 :alt="title || ''"
                 class="object-cover w-full h-full transition duration-300 transform"
                 :class="cta && cta.href ? 'group-hover:scale-110' : ''"
                 sizes="xs:100vw sm:786px md:1024px lg:1280px xl:1536px xxl:1920px"
                 loading="lazy"
        />
      </div>
      <div class="absolute inset-0 w-full h-full transform"
           :style="{
             background: 'linear-gradient(360deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%)',
           }"
      />
      <div class="md:max-w-md lg:max-w-lg">
        <h3 class="relative text-2xl sm:text-3xl md:text-4xl lg:text-5xl lg:leading-tight">
          {{ title }}
        </h3>
        <div v-if="copy"
             class="relative mt-2 font-normal md:mt-6"
             v-html="copy"
        />
      </div>
      <ButtonBase v-if="cta"
                  :type="cta.type"
                  :label="cta.title"
                  :href-type="hrefType"
                  class="relative flex flex-shrink-0 mt-8 pointer-events-none"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, resolveComponent } from 'vue'
import { useLinkType } from '../../composables/link-type'
import type { CtaType } from '../../types/button'

type CardFullWidth = {
  title?: string
  copy?: string
  cta?: CtaType
  backgroundImage: string
}

const props = defineProps<CardFullWidth>()
const { $event: trackEvent } = useNuxtApp()

const componentToShow = computed(() => {
  if (props.cta && props.cta.href && props.cta.href.length > 0) {
    return resolveComponent('NuxtLink')
  }
  return 'div'
})

const hrefType = computed(() => {
  if (props.cta && props.cta.href && props.cta.href.length > 0) {
    return useLinkType(props.cta.href)
  }

  return null
})

function handleClick() {
  trackEvent('Card Click', {
    props: {
      ['Type']: 'full-width',
      ['Title']: props.title,
      ['URL']: props.cta?.href,
      ['Background Image']: props.backgroundImage
    }
  })
}
</script>
